<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="slot_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('slot_type')">
                            <slot-type-selectbox ref="slotType" v-model="formData.slot_type"
                                                 :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row v-show="formData.slot_type">
                <b-col cols="12">
                    <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester')">
                            <semester-numbered-selectbox v-model="formData.semester"
                                                         :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" v-show="slotType && slotType.filter=='course'">
                    <ValidationProvider name="course_id" :rules="slotType && slotType.filter=='course' ? 'required':''" v-slot="{valid, errors}">
                        <b-form-group :label="$t('course')">
                            <course-auto-complete v-model="formData.course_id"
                                                  :validateError="errors[0]"
                                                  :value-object="courseObject"
                                                  @courseSelected="changeCredits"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" v-show="slotType && !['course','none'].includes(slotType.filter)">
                    <ValidationProvider name="pool_id" :rules="slotType && !['course','none'].includes(slotType.filter) ? 'required':''" v-slot="{valid, errors}">
                        <b-form-group :label="$t('elective_pools_title')">
                            <elective-pool-selectbox v-model="formData.pool_id"
                                                     :program-code="studentProgramCode"
                                                     :type="formData.slot_type"
                                                     :validateError="errors[0]"
                                                     :value-object="courseObject"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6">
                    <ValidationProvider name="credit" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('credit')">
                            <b-form-input type="number"

                                          v-model="formData.credit"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm" />
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import CourseAutoComplete from '@/components/interactive-fields/CourseAutoComplete';
import ElectivePoolSelectbox from '@/components/interactive-fields/ElectivePoolSelectbox';
import SemesterNumberedSelectbox from '@/components/interactive-fields/SemesterNumberedSelectbox';
import SlotTypeSelectbox from '@/components/interactive-fields/SlotTypeSelectbox';

// Services

import StudentSlotService from '@/services/StudentSlotService';
// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    components: {
        CourseAutoComplete,
        ElectivePoolSelectbox,
        SemesterNumberedSelectbox,
        SlotTypeSelectbox,

        ValidationProvider,
        ValidationObserver
    },
    props: {
        formId: {
            type: Number
        },
        studentProgramId: {
            type: Number
        },
        studentProgramCode: {
            type: String
        }
    },
    data() {
        return {
            courseObject: {
                value: null,
                text: null
            },
            formData: {
                student_program_id: null,
                slot_id: null,
                slot_type: null,
                course_id: null,
                pool_id: null,
                semester: null,
                credit: null,
            },
            formLoading: false,
            slotType:null
        }
    },
    created() {
        this.get(this.formId);
    },
    mounted() {
        this.$refs.formModalValidate.reset()
    },
    methods: {
        async get(id) {
            await StudentSlotService.get(id)
                .then(response => {
                    let data = response.data.data;

                    this.formData.student_program_id = data.student_program_id;
                    this.formData.slot_id = data.slot_id;
                    this.formData.slot_type = data.slot_type;
                    this.formData.course_id = data.course_id;
                    this.formData.pool_id = data.pool_id;
                    this.formData.semester = data.semester;
                    this.formData.credit = data.credit;

                    this.courseObject = {
                        value: data.course_id,
                        text: this.getLocaleText(data.course, 'name')
                    }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formLoading = true;
                StudentSlotService.update(this.formId, this.formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$refs.formModalValidate.reset();
                        this.$emit('updateFormSuccess', true);
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })

            }
        },
        changeCredits(course){
            this.formData.credit = course.item.credit;
        }
    },
    watch: {
        'formData.slot_type': function (selected) {
            this.slotType = this.$refs.slotType.getItem(selected);
        }
    },
}
</script>

